<template>
  <app-center>
    <template v-slot:page>
      <a href="/etsy-extension-v0.1.0-production.zip"><h1 class="primary--text" style="margin-bottom: 3rem">Click để tải extension</h1></a>
      <h2 class="primary--text">Hướng dẫn cài đặt extension</h2>
      <v-row>
        <v-col cols="6" offset="3">
          <h3>Cài extension lên chrome</h3>
          <video controls style="width: 100%;padding: 1rem 3rem;">
            <source src="/chromeExtensionGuide.mp4" type="video/mp4">
          </video>
        </v-col>
        <v-col cols="6" offset="3">
          <h3>Cài extension lên Cốc cốc</h3>
          <video controls style="width: 100%;padding: 1rem 3rem;">
            <source src="/coccocExtensionGuide.mp4" type="video/mp4">
          </video>
        </v-col>
      </v-row>
    </template>
  </app-center>
</template>
<script>
import AppCenter from '@/admin/widget/AppCenter.vue';
export default {
  name: 'ExtensionGuide',
  components: {
    AppCenter,
  }
}
</script>
